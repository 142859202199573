document.addEventListener('DOMContentLoaded', function () {
  const swiperElement = document.querySelector('.prices-swiper');
  let pricesSwiper = null;

  const initSwiper = () => {
    if (typeof Swiper !== 'undefined' && swiperElement && !pricesSwiper) {
      pricesSwiper = new Swiper(swiperElement, {
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        pagination: {
          el: '.prices-swiper-pagination',
          clickable: true,
        },
        navigation: {
          prevEl: '.prices-swiper-btn-prev',
          nextEl: '.prices-swiper-btn-next',
        },
        loop: true,
        speed: 600,
        slidesPerView: 1,
        spaceBetween: 16,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        breakpoints: {
          1280: {
            slidesPerView: 4,
          },
        },
      });
    }
  };

  const destroySwiper = () => {
    if (swiperElement && pricesSwiper) {
      pricesSwiper.destroy(true, true);
      pricesSwiper = null;
    }
  };

  // Функція для ввімкнення/вимкнення swiper залежно від ширини вікна
  const handleResize = () => {
    const mediaQuery = window.matchMedia('(max-width: 1280px)');
    if (swiperElement) {
      if (mediaQuery.matches) {
        initSwiper(); // ініціалізуємо swiper, якщо ширина <= 1280px
      } else {
        destroySwiper(); // знищуємо swiper, якщо ширина > 1280px
      }
    }
  };

  // Виклик при завантаженні
  handleResize();

  // Відстеження зміни розміру вікна
  window.addEventListener('resize', handleResize);

  // Перевірка на наявність Swiper
  if (typeof Swiper === 'undefined') {
    window.addEventListener('load', handleResize);
  }
});
